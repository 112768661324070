@font-face {
  font-family: notoRegular;
  src: url("../fonts/noto/NotoSansJP-Regular.otf");
}
@font-face {
  font-family: notoBold;
  src: url("../fonts/noto/NotoSansJP-Bold.otf");
}

body {
  font-family: notoRegular;
  font-size: 16px;
  letter-spacing: 1px;
  overflow-x: hidden;
}
.app--main__content {
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
}
.container {
  max-width: 1600px;
  margin: 0 auto;
}
.mini--title {
  font-family: notoBold;
  font-size: 13px;
  text-transform: uppercase;
  color: #856514;
  letter-spacing: 1.2px;
  text-align: center;
}
.mini--title:after {
  content: "";
  display: flex;
  background-color: #856514;
  height: 3px;
  margin: 0 auto;
  margin-top: 5px;
  width: 80%;
}
.title {
  font-size: 3em;
  margin: 15px;
}
.button {
  position: relative;
  padding: 1em 1.5em;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  font-size: 18px;
  margin: 1em 0.8em;
}
.line--top:before {
  content: "";
  display: flex;
  background-color: #8d6708;
  height: 3px;
  margin-bottom: 10px;
  width: 100px;
}
.line--top.dark:before {
  background-color: #dba419;
}
.button--link {
  color: #fafafa;
}
.button--link.gold {
  color: #dba419;
}
.button--link::after,
.button--link::before {
  content: "";
  display: block;
  position: absolute;
  width: 20%;
  height: 20%;
  border: 2px solid;
  transition: all 0.6s ease;
  border-radius: 2px;
}
.button--link::after {
  bottom: 0;
  right: 0;
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: #fafafa;
  border-right-color: #fafafa;
}
.button--link.gold::after {
  border-bottom-color: #dba419;
  border-right-color: #dba419;
}
.button--link::before {
  top: 0;
  left: 0;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-top-color: #fafafa;
  border-left-color: #fafafa;
}
.button--link.gold::before {
  border-top-color: #dba419;
  border-left-color: #dba419;
}
.button--link:hover:after,
.button--link:hover:before {
  width: 100%;
  height: 100%;
}
.parallax {
  background-attachment: fixed;
  background-position: center center;
  height: 40vh;
  filter: brightness(0.9);
  width: 100vw;
}
.grey {
  background-color: #f2f1ee !important;
}
.rec-slider {
  align-items: flex-start;
}
.rec-arrow {
  background: transparent !important;
  box-shadow: none !important;
  font-size: 2.9em !important;
  color: grey !important;
}
.rec-item-wrapper {
  height: 100%;
  align-items: center;
}
.rec-dot_active {
  border-radius: 0 !important;
  transform: rotate(45deg) !important;
  background: transparent !important;
  box-shadow: none !important;
  border: 2px solid #8d6708 !important;
}
.rec-dot:hover {
  box-shadow: 0 0 1px 3px #8d6708 !important;
}
.rec-dot_active:hover {
  box-shadow: none !important;
}
.rec-pagination {
  align-items: center;
}
.hexagon {
  position: relative;
  width: 150px;
  height: 50px;
  background-color: transparent;
  margin: 45px 0;
  border-left: solid 3px #8d6708;
  border-right: solid 3px #8d6708;
  justify-content: center;
  display: flex;
  align-items: center;
}

.hexagon:before,
.hexagon:after {
  content: "";
  position: absolute;
  z-index: 1;
  width: 106px;
  height: 106px;
  -webkit-transform: scaleY(0.5774) rotate(-45deg);
  -ms-transform: scaleY(0.5774) rotate(-45deg);
  transform: scaleY(0.5) rotate(-45deg);
  background-color: inherit;
  left: 20px;
}

.hexagon:before {
  top: -55px;
  border-top: solid 4px #8d6708;
  border-right: solid 4px #8d6708;
}

.hexagon:after {
  bottom: -55px;
  border-bottom: solid 4px #8d6708;
  border-left: solid 4px #8d6708;
}

.info--list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}
.info--list li {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.info--list li span {
  display: flex;
  flex-direction: row;
}
.info--list li:before {
  content: "\F012E";
  display: inline-block;
  font-size: 12px;
  color: #dba419;
  transform: rotate(45deg);
  font-family: "Material Design Icons";
  position: absolute;
  left: -20px;
}
.info--list.dark span:before {
  background-color: #8d6708;
}
.active--decorator:before,
.active--decorator:after {
  content: "";
  display: block;
  position: absolute;
  width: 20%;
  height: 20%;
  border: 2px solid;
  border-radius: 2px;
}

@media screen and (max-width: 768px) {
  .app--main__content {
    min-height: calc(100vh - 138px);
  }
}
@media screen and (max-width: 459px) {
  .title {
    font-size: 2em;
  }
}
